import React from 'react';

const Pagination = ({ currentPage, totalPages, paginate }) => {
  const paginationLimit = 5;
  const maxPageDisplay = paginationLimit * 2 + 1;

  const firstDisplayedPage = Math.max(1, currentPage - paginationLimit);
  const lastDisplayedPage = Math.min(totalPages, firstDisplayedPage + maxPageDisplay - 1);

  const pageLinks = [];
  for (let page = firstDisplayedPage; page <= lastDisplayedPage; page++) {
    pageLinks.push(
      <button
        key={page}
        onClick={() => paginate(page)}
        className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
      >
        {page}
      </button>
    );
  }

  const previousPages = firstDisplayedPage > 1 ? (
    <button className="pagination-btn" onClick={() => paginate(firstDisplayedPage - 1)}>
      &lt;&lt;
    </button>
  ) : null;

  const nextPages = lastDisplayedPage < totalPages ? (
    <button className="pagination-btn" onClick={() => paginate(lastDisplayedPage + 1)}>
      &gt;&gt;
    </button>
  ) : null;

  return (
    <div className="pagination-container">
      <div className="pagination">
        {previousPages}
        {pageLinks}
        {nextPages}
      </div>
    </div>
  );
};

export default Pagination;
