import React, { useState, useEffect } from 'react';
import api from '../../helper/api';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import '../../styles/table-paginate.css';
import { formatDate } from '../../helper/date';

const config = require('../../config/config');
const towerOptions = config.tower;

function AdminHistoricalRecords() {
  const [historicalRecords, setHistoricalRecords] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('date');
  const [unit, setUnit] = useState('');
  const [tower, setTower] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Add a useEffect hook to set the initial values
  useEffect(() => {
    setUnit('');
    setTower('');
  }, [selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let params = { unit, tower };
  
        if (selectedDate) {
          params = {
            ...params,
            month: selectedDate.getMonth() + 1,
            year: selectedDate.getFullYear(),
          };
        }
  
        const response = await api.get('/historicalresidents', { params });
        setHistoricalRecords(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [unit, tower, selectedDate]);
  

  const toggleSortOrder = (column) => {
    if (column === sortColumn) {
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortOrder('asc');
      setSortColumn(column);
    }
  };

  const filteredhistoricalRecords = historicalRecords.filter((records) => {
    const searchPattern = new RegExp(searchText, 'i');
    const inSearch =
      searchText === '' ||
      searchPattern.test(records.unit) ||
      searchPattern.test(records.tower) ||
      searchPattern.test(records.eventType);

    return inSearch;
  });

  filteredhistoricalRecords.sort((a, b) => {
    if (sortColumn === 'unit') {
      if (sortOrder === 'asc') {
        return a._id < b._id ? -1 : 1;
      } else {
        return a._id > b._id ? -1 : 1;
      }
    }
    return 0;
  });

  filteredhistoricalRecords.sort((a, b) => {
    if ((sortColumn === 'unit') || (sortColumn === 'tower')) {
      if (sortOrder === 'asc') {
        return a._id < b._id ? -1 : 1;
      } else {
        return a._id > b._id ? -1 : 1;
      }
    } else if (sortColumn === 'date') {
      // Sorting logic for 'date' column
      const dateA = new Date(a.eventTimestamp);
      const dateB = new Date(b.eventTimestamp);
      
      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    }
    return 0;
  });
  
  const renderSortIcon = (column) => (
    sortColumn === column && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>
  );

  return (
    <div className="admin-component-tile">
      <h1>Historical Records</h1>
      <div className="form-grid">
        <div className="form-group">
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <div className="form-grid">
        <div className="date-picker-container">
          <label>Filter by Month: </label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showMonthYearPicker
            dateFormat="MM/yyyy"
          />
          <button onClick={() => setSelectedDate(null)}>Show All</button>
        </div>
      </div>
      <div className="form-grid">
        <div className="form-group">
          <p>Unit</p>
          <input
            name="unit"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            aria-label="Unit"
          />
        </div>
        <div className="form-group">
          <p>Tower</p>
          <select
            name="tower"
            value={tower}
            onChange={(e) => setTower(e.target.value)}
            aria-label="Tower"
          >
            <option value="">Select Tower</option>
            {towerOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <p>Showing {filteredhistoricalRecords.length} records</p>
      <div className="margin-top">
        <table className="table">
          <thead>
            <tr>
              <th onClick={() => toggleSortOrder('unit')}>
                Unit {renderSortIcon('unit')}</th>
              <th onClick={() => toggleSortOrder('tower')}>
                Tower {renderSortIcon('tower')}</th>
              <th>Type</th>
              <th onClick={() => toggleSortOrder('date')}>
                Date {renderSortIcon('date')}</th>
              <th>Email 1</th>
              <th>Email 2</th>
            </tr>
          </thead>
          <tbody>
            {filteredhistoricalRecords.map((record) => (
              <tr key={record._id}>
                <td>{record.unit}</td>
                <td>{record.tower}</td>
                <td>{record.eventType}</td>
                <td>{formatDate(record.eventTimestamp, 'YYYY-MM-DD')}</td>
                <td className="cell-tracking" title={record.eventData?.email1}>{record.eventData?.email1}</td>
                <td className="cell-tracking" title={record.eventData?.email2}>{record.eventData?.email2}</td>              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminHistoricalRecords;
