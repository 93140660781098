import { useState } from "react";
import validator from "validator"; 
import ConfirmationPopup from './ConfirmationPopup';
import EmailConfirmationPopup from './EmailConfirmationPopup';

const config = require('../config/config');
const unitmin = config.unit.min;
const unitmax = config.unit.max;
const towerOptions = config.tower;

const mailuser = process.env.REACT_APP_MAILUSER;

const UserForm = () => {
    const [residentData, setResidentData] = useState({
        unit: '',
        tower: '',
        email1: '',
        email2: '',
    });
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [showEmailConfirmationPopup, setShowEmailConfirmationPopup] = useState(false);

    const handleConfirm = () => {
        setShowConfirmPopup(false);
        handleSave();
      };
    
      const handleCancel = () => {
        setShowConfirmPopup(false);
      };

    const handleSaveClick = () => {
        const errors = validateData();
      
        if (Object.keys(errors).length) {
          setErrors(errors);
          // there are errors, do not show the confirmation popup
          return;
        }
      
        setErrors({});
        setShowConfirmPopup(true);
    };

    const handleOk = () => {
        setShowEmailConfirmationPopup(false);
        resetForm();
      };
            
    const validateData = () => {
        let errors = {};

        if (!residentData.unit) {
            errors.unit = 'Unit is required';
        } 

        if (!validator.isInt(residentData.unit) || residentData.unit < unitmin || residentData.unit > unitmax) {
            errors.unit = `Invalid unit number`;
        }

        if (!residentData.tower) { // add validation for tower selection
            errors.tower = 'Tower is required';
        }

        if (!validator.isEmail(residentData.email1)) {
            errors.email1 = "A valid email is required";
        } else if (residentData.email1.endsWith("@shaw.ca")) {
            errors.email1 = "Email addresses with 'shaw.ca' domain are not allowed until Shaw resolves a technical problem on their end";
        }

        if (residentData.email2) 
        {
            if (!validator.isEmail(residentData.email2)) {
                errors.email2 = "A valid email is required";
            } else if (residentData.email2.endsWith("@shaw.ca")) {
                errors.email2 = "Email addresses with 'shaw.ca' domain are not allowed until Shaw resolves a technical problem on their end";
            } else if (residentData.email2 === residentData.email1) {
                errors.email2 = "Email 2 is the same as Email 1.  If you want to register one email address, you can leave Email 2 blank";
            }
        };
        return errors;
    };

    const resetForm = () => {
        setResidentData({ unit: '', tower: '', email1: '', email2: '' });
        setIsSuccess(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (isSuccess) {
            resetForm();
        }
        setResidentData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = async () => {
        const errors = validateData();
        if (Object.keys(errors).length) {
            setErrors(errors);
            // there are errors, do not continue saving
            return;
        }

        setErrors({});
        
        try {
            //make a POST request to the API endpoint
            const response = await fetch(process.env.REACT_APP_API_URL + '/residents', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(residentData),
            });
    
            if (response.status === 409) {
                // Handle the conflict (user already exists)
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    general: 'This unit has already been registered. Please contact concierge for further assistance.',
                }));
            } else if (!response.ok) {
                // Handle other errors
                throw new Error('An error occurred during the request.');
            } else {                
                setIsSuccess(true); // Set isSuccess to true when the POST request is successful
                setShowEmailConfirmationPopup(true); // Show the new confirmation popup when the form submission is successful
            }

        } catch (error) {
            console.error(error);
            setErrors((prevErrors) => ({
                ...prevErrors,
                general: 'An unexpected error occurred. Please try again later.',
            }));

        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave();
    };

    return (
        <div className='resident-component-tile'>
            <form onSubmit={handleSubmit}>
                <h1>Welcome</h1>
                <div className='margin-top'>
                    Welcome to Lumina Delivery Registration site. This form is used to register up to two email addresses for package delivery notification.
                    <br /><br />
                    This app will send email alerts from <b>{ mailuser }</b>.  Please check your spam folder in case emails from this address get tagged as such.
                    <br /><br />
                    Use this to enter new email contact information for your unit
                    <br /><br />
                </div>

                <div className="form-grid">
                    <div className="form-group">
                        <p>Unit Number</p>
                        <input name="unit" value={residentData.unit} onChange={handleChange} aria-label="Unit" />
                        <div style={{ color: "red" }}>{errors.unit}</div>
                    </div>
                    <div className="form-group">
                        <p>Tower</p>
                        <select name="tower" value={residentData.tower} onChange={handleChange} aria-label="Tower">
                            <option value="">Select Tower</option>
                            {towerOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        <div style={{ color: "red" }}>{errors.tower}</div>
                    </div>                
                    <div className="form-group">
                        <p>Email 1</p>
                        <input name="email1" value={residentData.email1} onChange={handleChange} aria-label="Email 1" />
                        <div style={{ color: "red" }}>{errors.email1}</div>
                    </div>
                    <div className="form-group">
                        <p>Email 2 (Optional)</p>
                        <input name="email2" value={residentData.email2} onChange={handleChange} aria-label="Email 2" />
                        <div style={{ color: "red" }}>{errors.email2}</div>
                    </div>
                </div>
                <div style={{ marginTop: "12px", color: "red" }}>{errors.general}</div>
                <div style={{ marginTop: "12px" }}>
                    <button type="button" onClick={handleSaveClick}>Save</button>
                </div>
            </form>
            {showConfirmPopup && (
            <ConfirmationPopup
                data={residentData}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />)}
            {showEmailConfirmationPopup && (
            <EmailConfirmationPopup
                onOk={handleOk}
            />)}  
        </div>       
    );
};

export default UserForm;