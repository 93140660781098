import React, { useState, useEffect } from 'react';
import api from '../../helper/api';
import { formatDate } from "../../helper/date";

function AdminPickup() {

  const [deliveries, setDeliveries] = useState([]);         // This is a state variable that will be used to store the deliveries
  const [selected, setSelected] = useState([]);             // This is a state variable that will be used to store the selected deliveries
  const [filterByUnit, setFilterByUnit] = useState(false);  // This is a state variable that will be used to filter the deliveries by unit
  const [filterByTower, setFilterByTower] = useState(false);  // This is a state variable that will be used to filter the deliveries by tower
  const [pickedupConciergeId, setpickedupConciergeId] = useState('');       // This is a state variable that will be used to store the pickeduppickedupConciergeId
  const [pickedupResidentId, setpickedupResidentId] = useState('');         // This is a state variable that will be used to store the pickedupResidentId  
  const [sortConfig, setSortConfig] = useState({ field: 'packageId', order: 'desc' }); // This is a state variable that will be used to sort the deliveries
  const [isSuccess, setIsSuccess] = useState(false);        // This is a state variable that will be used to display a success message
  const [message, setMessage] = useState('');               // This is a state variable that will be used to store the success message



  useEffect(() => {
    fetchData();
  }, []);

  // This function fetches the deliveries from the database
  async function fetchData() {
    try {
        // Calculate the date 6 months ago
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        const response = await api.get('/deliveries?pickedupAt', {
          params: {
            startDate: sixMonthsAgo.toISOString(), // Set the startDate query parameter to 6 months ago
          },
        });

        // const response = await api.get('/deliveries?pickedupAt');
        setDeliveries(response.data);
      } catch (error) {
        console.log(error);
      }
  }

  //  This function sorts the deliveries by the field and order specified in the config object
  function sortDeliveries(deliveries, config) {
    const { field, order } = config;
  
    if (!field) return deliveries;
  
    const sortedDeliveries = [...deliveries].sort((a, b) => {
      if (a[field] < b[field]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  
    return sortedDeliveries;
  }

// This function filters the deliveries by unit and tower
const sortedDeliveries = sortDeliveries(deliveries, sortConfig);
const filteredDeliveries = filterByUnit && filterByTower
  ? sortedDeliveries.filter(delivery => delivery.unit === filterByUnit && delivery.tower === filterByTower)
  : filterByUnit
    ? sortedDeliveries.filter(delivery => delivery.unit === filterByUnit)
    : filterByTower
      ? sortedDeliveries.filter(delivery => delivery.tower === filterByTower)
      : sortedDeliveries;

  // This function handles the sorting of the deliveries
  function handleHeaderClick(field) {
    let order = 'asc';
    if (sortConfig.field === field && sortConfig.order === 'asc') {
      order = 'desc';
    }
    setSortConfig({ field, order });
  }    

// This function filters the deliveries by unit and tower when row is clicked
function handleRowClick(packageId, unit, tower) {
  if (selected.includes(packageId)) {
    setSelected(selected.filter(id => id !== packageId));
    if (selected.length === 1) {
      setFilterByUnit(false);
      setFilterByTower(false);
    }
  } else {
    setSelected([...selected, packageId]);
    setFilterByUnit(unit);
    setFilterByTower(tower);
  }
}

  // This function returns the class name for the sort icon
  function getSortIconClass(field) {
    if (sortConfig.field !== field) {
      return 'sort-icon sort-inactive';
    }
  
    return sortConfig.order === 'asc' ? 'sort-icon sort-asc' : 'sort-icon sort-desc';
  }

  // This function handles the pickup of the selected packages  
  const handlePickup = async () => {
    // iterate through selected packages and make PATCH requests
    const promises = selected.map((packageId) =>
      api.patch(`/deliveries/pickup/${packageId}`, { pickedupConciergeId, pickedupResidentId })
    );
    const responses = await Promise.all(promises);
  
    let successCount = 0;
    let emailNotFoundCount = 0;
    let notFoundCount = 0;
    let errorCount = 0;
  
    // loop through responses and update counts
    responses.forEach((response) => {
      if (response.status === 200) {
        successCount++;
      } else if (response.status === 207) {
        emailNotFoundCount++;
      } else if (response.status === 404) {
        notFoundCount++;
      } else {
        errorCount++;
      }
    });
  
    // construct message based on counts
    let message = `${successCount} package(s) successfully picked up.`;
    if (emailNotFoundCount > 0) {
      message += ` ${emailNotFoundCount} package(s) were updated but no active email found for a resident.`;
    }
    if (notFoundCount > 0) {
      message += ` ${notFoundCount} package(s) not found.`;
    }
    if (errorCount > 0) {
      message += ` ${errorCount} package(s) encountered an unexpected error.`;
    }
  
    // set message and success state variables
    setMessage(message);
    setIsSuccess(true);
  
    // wait for 1 second to let the table update
    await new Promise((resolve) => setTimeout(resolve, 1000));
  
    // refresh table data
    await fetchData();

      // clear selected packages
    setSelected([]);
  
    // remove message after 5 seconds
    setTimeout(() => {
      setIsSuccess(false);
    }, 5000);
  };
  
  return (
    <div className="admin-component-tile">
      <h1>Pickup</h1>
      <div className = "margin-top">
        Select the packages to pickup and enter the concierge ID and resident ID <br />
        Selecting a package will filter the table to show only packages for that unit
      </div>
      <br></br>
      <p>Showing <b>{filteredDeliveries.length}</b> records from the last 6 months</p>
      <div className = "margin-top">
        <table className='table'>
          <thead>
            <tr>        
              <th className="col-packageId" onClick={() => handleHeaderClick('packageId')}>Package ID <span className={getSortIconClass('packageId')}>&#8597;</span></th>
              <th className="col-unit" onClick={() => handleHeaderClick('unit')}>Unit <span className={getSortIconClass('unit')}>&#8597;</span></th>
              <th className="col-tower" onClick={() => handleHeaderClick('tower')}>Tower <span className={getSortIconClass('tower')}>&#8597;</span></th>
              <th className="col-courier" onClick={() => handleHeaderClick('courier')}>Courier <span className={getSortIconClass('courier')}>&#8597;</span></th>
              <th className="col-packagetype">Type</th>
              <th className="col-tracking">Tracking</th>
              <th className="col-location">Location</th>
              <th className="col-conciergeId" onClick={() => handleHeaderClick('conciergeId')}>Concierge ID <span className={getSortIconClass('conciergeId')}>&#8597;</span></th>
              <th className="col-createdAt" onClick={() => handleHeaderClick('createdAt')}>Delivery Date <span className={getSortIconClass('createdAt')}>&#8597;</span></th>
            </tr>
          </thead>
          <tbody>
            {filteredDeliveries.map(delivery => (
              <tr
                key={delivery.packageId}
                onClick={() => handleRowClick(delivery.packageId, delivery.unit, delivery.tower)}
                style={{
                  backgroundColor: selected.includes(delivery.packageId) ? 'lightblue' : 'white',
                }}
              >         
                <td>{delivery.packageId}</td>              
                <td>{delivery.unit}</td>
                <td>{delivery.tower}</td>
                <td>{delivery.courier}</td>
                <td>{delivery.packagetype}</td>
                <td><div className="cell-tracking" title={delivery.tracking}>{delivery.tracking}</div></td>
                <td>{delivery.location}</td>
                <td>{delivery.conciergeId}</td>
                <td>{formatDate(delivery.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selected.length > 0 && (
        <div>
          <label style={{ display: 'block', marginBottom: '10px' }}>
            Concierge Name or ID:
            <input
              type="text"
              value={pickedupConciergeId}
              onChange={e => setpickedupConciergeId(e.target.value)}
              style={{ marginLeft: '10px' }}
            />
          </label>
          <label style={{ display: 'block', marginBottom: '10px' }}>
            Resident Name or ID:
            <input
              type="text"
              value={pickedupResidentId}
              onChange={e => setpickedupResidentId(e.target.value)}
              style={{ marginLeft: '10px' }}
            />
          </label>
          <div className="submit-container">
            <button type="button" onClick={handlePickup}>Pickup Selected Packages</button>
          </div>          
        </div>
      )}
      {isSuccess && (
        <div className='success-message'>
          {message}
        </div>
      )}
    </div>
  );
}







export default AdminPickup;
